import React from 'react'
import VideoLooper from 'react-video-looper'
import './index.scss'

import sampleVideo from './Southtri.mp4'
import sampleVideoMobil from './SouthtriWeb.mp4'
 
export default function App() {
  return (
    <div className='containerVideos'>
      <div className='containerPage'>
        <VideoLooper source={sampleVideo} start={0} end={22}  muted={true} loopCount={3000} />
      </div>
      <div className='containerPageMobil'>
        <VideoLooper  id="containerVideo" source={sampleVideoMobil} start={0} end={22}  muted={true} loopCount={3000} />
      </div>
    </div>
  )
}